import React from 'react'
import styled from 'styled-components'
import { SEO } from '../components/SEO'
import { Link } from 'gatsby'

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: red;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }
`

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>
      <Link to="/">Back to Homepage</Link>
    </p>
  </Container>
)

export default NotFoundPage
